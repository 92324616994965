import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/AdminDashboard.css';

function AdminDashboard(props) {
  const { isLoggedIn, userData } = props;
  const [files, setFiles] = useState([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [isFilesLoaded, setIsFilesLoaded] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);

  const [selectedPlatforms, setSelectedPlatforms] = useState(['instagram', 'tiktok', 'reddit', 'twitter']);
  const [instagramDesc, setInstagramDesc] = useState('');
  const [instagramHashtags, setInstagramHashtags] = useState('');
  const [tiktokDesc, setTiktokDesc] = useState('');
  const [tiktokHashtags, setTiktokHashtags] = useState('');
  const [redditTitle, setRedditTitle] = useState('');
  const [redditDesc, setRedditDesc] = useState('');
  const [redditHashtags, setRedditHashtags] = useState('');
  const [twitterDesc, setTwitterDesc] = useState('');
  const [twitterHashtags, setTwitterHashtags] = useState('');


  // useEffect(() => {
  //   if (currentMedia && currentMedia.fileType === 'image' && selectedPlatforms.includes('tiktok')) {
  //     setSelectedPlatforms((prevPlatforms) => prevPlatforms.filter((p) => p !== 'tiktok'));
  //   }
  // }, [currentMedia, selectedPlatforms]);



  const getFiles = async () => {
    try {
      const response = await axios.get(`http://localhost:8000/files`);
      if (response.status === 200) {
        console.log(response.data.files);
        setFiles(response.data.files);
        setIsFilesLoaded(true);
        setSelectedType('');
      }
    } catch (error) {
      console.error("Error fetching user devices:", error);
    }
  };

  const deleteMedia = async () => {
    const currentMedia = files[currentMediaIndex];
    if (!window.confirm("Are you sure you want to delet this media?")) {
      return;
    }
    try {
      await axios.delete(`http://localhost:8000/deleteMedia/${currentMedia.idImage}`);
      // Supprimer le media de la liste des fichiers
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.idImage !== currentMedia.idImage)
      );
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };

  const sendData = async () => {
    const currentMedia = files[currentMediaIndex];
    const selectedTypeDeg = selectedType; // Récupérer la valeur du type sélectionné
    const selectedDescriptions = {};

    if (!selectedTypeDeg) {
      alert("Please select a content degree");
      return;
    }

    // Récupérer les descriptions uniquement pour les plateformes sélectionnées
    if (selectedPlatforms.includes('instagram')) {
      selectedDescriptions.instagram = {
        desc: instagramDesc,
        hashtags: instagramHashtags
      };
    }
    if (selectedPlatforms.includes('tiktok')) {
      selectedDescriptions.tiktok = {
        desc: tiktokDesc,
        hashtags: tiktokHashtags
      };
    }
    if (selectedPlatforms.includes('reddit')) {
      selectedDescriptions.reddit = {
        title: redditTitle,
        desc: redditDesc,
        hashtags: redditHashtags
      };
    }
    if (selectedPlatforms.includes('twitter')) {
      selectedDescriptions.twitter = {
        desc: twitterDesc,
        hashtags: twitterHashtags
      };
    }

    if (!window.confirm("Are you sure you want to create this post?")) {
      return;
    }

    try {
      // Envoyer les informations au serveur
      const response = await axios.post(`http://localhost:8000/createPosts`, {
          mediaId: currentMedia.idImage,
          type: selectedTypeDeg,
          plateforms: selectedPlatforms,
          descriptions: selectedDescriptions,
        }
      );
      if (response.status === 200) {
        setSelectedType('');
        setSelectedPlatforms(['instagram', 'tiktok', 'reddit', 'twitter']);
        setInstagramDesc('');
        setInstagramHashtags('');
        setTiktokDesc('');
        setTiktokHashtags('');
        setRedditTitle('');
        setRedditDesc('');
        setRedditHashtags('');
        setTwitterDesc('');
        setTwitterHashtags('');
      }
      // Supprimer le media de la liste des fichiers
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.idImage !== currentMedia.idImage)
      );
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const handlePrevMedia = () => {
    setCurrentMediaIndex((prevIndex) => prevIndex - 1);
    setSelectedType('');
  };

  const handleNextMedia = () => {
    setCurrentMediaIndex((prevIndex) => prevIndex + 1);
    setSelectedType('');
  };

  const handleGoToMedia = (index) => {
    setCurrentMediaIndex(index);
    setSelectedType('');
  };

  const handleTypeSelection = (type) => {
    setSelectedType(type);
  };

  const handleMouseEnter = () => {
    setShowDownloadButton(true);
  };

  const handleMouseLeave = () => {
    setShowDownloadButton(false);
  };

  const handleDownload = () => {
    const currentMedia = files[currentMediaIndex];
    const mediaUrl = `http://localhost:8000/uploads/${currentMedia.name}`;
    saveAs(mediaUrl, currentMedia.name);
  };

  const handlePlatformSelection = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      // Plateforme déjà sélectionnée, la désélectionner
      setSelectedPlatforms((prevPlatforms) => prevPlatforms.filter((p) => p !== platform));
    } else {
      // Plateforme non sélectionnée, la sélectionner
      setSelectedPlatforms((prevPlatforms) => [...prevPlatforms, platform]);
    }
  };

  const filePreviews = files.map((file, index) => {
    return (
      <div key={file.idMedia} onClick={() => handleGoToMedia(index)} className="admin-file-preview">
        {file.fileType === 'image' ? (
          <img src={"http://localhost:8000/uploads/"+file.name} alt={file.name} className="admin-file-preview-image" style={{ width: 100, height: 115 }} />
        ) : (
          <video src={"http://localhost:8000/uploads/"+file.name} alt={file.name} className="admin-file-preview-video" style={{ width: 100, height: 115 }} />
        )}
      </div>
    );
  });


const bigPreview = files.map((file, index) => {
  return (
    <>
      {index === currentMediaIndex && (
        <>
          {file.fileType === 'image' ? (
            <img src={`http://localhost:8000/uploads/${file.name}`} alt={file.name} onLoad={() => setCurrentMedia(file)} />
          ) : (
            <video src={`http://localhost:8000/uploads/${file.name}`} controls alt={file.name} onLoad={() => setCurrentMedia(file)}/>
          )}
        </>
      )}
    </>
  );
});


  return (
    <div className='checks-ctnt'>
      <div className='first-line'>
        <div className='large-preview'>
          <div className='admin-file-preview-lp' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {!isFilesLoaded ? (
              <button onClick={getFiles}>Load files</button>
            ) : (
              <>
                {showDownloadButton && (
                  <button className="download-button" onClick={handleDownload}>Download</button>
                )}
                {bigPreview}
              </>
            )}
          </div>
          <div className='actions'>
            <div className='move'>
              <button className='prev' onClick={handlePrevMedia} disabled={currentMediaIndex === 0}>Prev</button>
              <button className='next' onClick={handleNextMedia} disabled={currentMediaIndex === files.length - 1}>Next</button>
            </div>
            <div className='type'>
        <div>
          <button className={`type-softsoft ${selectedType === 'softsoft' ? 'selected' : ''}`} onClick={() => handleTypeSelection('softsoft')}>SoftSoft</button>
          <button className={`type-soft ${selectedType === 'soft' ? 'selected' : ''}`} onClick={() => handleTypeSelection('soft')}>Soft</button>
        </div>
        <div>
          <button className={`type-medium ${selectedType === 'medium' ? 'selected' : ''}`} onClick={() => handleTypeSelection('medium')}>Medium</button>
          <button className={`type-hard ${selectedType === 'hard' ? 'selected' : ''}`}onClick={() => handleTypeSelection('hard')}>Hard</button>
        </div>
      </div>
            <div className='send'>
              <button className='accept' onClick={sendData}>Accept</button>
              <button className='reject' onClick={deleteMedia}>Reject</button>
            </div>
          </div>
        </div>
        <div className='descriptions'>
          <div className='row1'>
            <div className='card2'>
              <div className='card2-head'>
                <div className='card2-header-ctnt'>
                <h3>Instagram</h3>
                  <div className='card2-actions'>
                    <div className='length-info'>
                      <div className='circle'></div>
                      <div className='circle'></div>
                    </div>
                    <div className='checkbox'>
                      <input type="checkbox" checked={selectedPlatforms.includes('instagram')} onChange={() => handlePlatformSelection('instagram')}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ctnt'>
              <textarea className="desc-desc" type="text" value={instagramDesc} onChange={(e) => setInstagramDesc(e.target.value)} />
              <div className="separator"></div>
              <textarea className="desc-hashtag" type="text" value={instagramHashtags} onChange={(e) => setInstagramHashtags(e.target.value)}/>
              </div>
            </div>
            <div className='card2'>
              <div className='card2-head'>
                <div className='card2-header-ctnt'>
                <h3>Tiktok</h3>
                  <div className='card2-actions'>
                    <div className='length-info'>
                      <div className='circle'></div>
                      <div className='circle'></div>
                    </div>
                    <div className='checkbox'>
                      <input type="checkbox" checked={selectedPlatforms.includes('tiktok')} onChange={() => handlePlatformSelection('tiktok')}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ctnt'>
                <textarea className="desc-desc" type="text" value={tiktokDesc} onChange={(e) => setTiktokDesc(e.target.value)} />
                <div className="separator"></div>
                <textarea className="desc-hashtag" type="text" value={tiktokHashtags} onChange={(e) => setTiktokHashtags(e.target.value)}/>
              </div>
            </div>
          </div>
          <div className='row2'>
            <div className='card2'>
              <div className='card2-head'>
                <div className='card2-header-ctnt'>
                <h3>Reddit</h3>
                  <div className='card2-actions'>
                    <div className='length-info'>
                      <div className='circle'></div>
                      <div className='circle'></div>
                    </div>
                    <div className='checkbox'>
                      <input type="checkbox" checked={selectedPlatforms.includes('reddit')} onChange={() => handlePlatformSelection('reddit')}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ctnt'>
                <textarea className="desc-desc" type="text" value={redditTitle} onChange={(e) => setRedditTitle(e.target.value)} />
                <div className='separator'></div>
                <textarea className="desc-desc" type="text" value={redditDesc} onChange={(e) => setRedditDesc(e.target.value)} />
                <div className='separator'></div>
                <textarea className="desc-desc" type="text" value={redditHashtags} onChange={(e) => setRedditHashtags(e.target.value)} />
              </div>
            </div>
            <div className='card2'>
              <div className='card2-head'>
                <div className='card2-header-ctnt'>
                <h3>Twitter</h3>
                  <div className='card2-actions'>
                    <div className='length-info'>
                      <div className='circle'></div>
                      <div className='circle'></div>
                    </div>
                    <div className='checkbox'>
                      <input type="checkbox" checked={selectedPlatforms.includes('twitter')} onChange={() => handlePlatformSelection('twitter')}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ctnt'>
                <textarea className="desc-desc" type="text" value={twitterDesc} onChange={(e) => setTwitterDesc(e.target.value)} />
                <div className="separator"></div>
                <textarea className="desc-hashtag" type="text" value={twitterHashtags} onChange={(e) => setTwitterHashtags(e.target.value)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='small-preview'>{filePreviews}</div>
    </div>
  );
}

export default AdminDashboard;
