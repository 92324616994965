import "../styles/PcNav.css"
// import logo from "../assets/logo.png"
// import icoUpload from "../assets/ico/icoUpload.svg"
// import icoPlann from "../assets/ico/icoPlann.svg"
// import icoSetting from "../assets/ico/icoSetting.svg"
import { Link } from "react-router-dom";
import React from 'react'



function PcNav(props) {
    const { userData } = props;
    const pathname = window.location.pathname;

    return ( pathname === "/" || pathname === "" || pathname === "/activation" ? null :
        <>
            <nav className="pc-nav">
                <div className="hellouser">
                    {/* <img src="{logo}" alt="" /> */}
                    <div className="logo-alt"></div>
                    <h2>Bonjour,<br/>{userData.firstName}</h2>
                </div>
                <div className="hellouser-separator"></div>
                <div className="nav-tabs">
                    <div className="nav-tab-1">
                        {userData.role === 0 &&
                            <ul>
                                <li className="tab tab1">
                                    <Link to="/admindashboard">
                                        {/* <img src="" alt="" /> */}
                                        <div className="ico-alt ico-upload"></div>
                                        <span className="link-separator"></span>
                                        <h3>Content checks</h3>
                                    </Link>
                                </li>
                                <li className="tab tab2">
                                    <Link to="/clients">
                                        {/* <img src="" alt="" /> */}
                                        <div className="ico-alt ico-plann"></div>
                                        <span className="link-separator"></span>
                                        <h3>Clients</h3>
                                    </Link>
                                </li>
                            </ul>
                            }
                        {userData.role === 1 &&
                            <ul>
                            <li className="tab tab1">
                                <Link to="/upload">
                                    {/* <img src="" alt="" /> */}
                                    <div className="ico-alt ico-upload"></div>
                                    <span className="link-separator"></span>
                                    <h3>Upload content</h3>
                                </Link>
                            </li>
                            {/* <li className="tab tab2">
                                <Link to="/plannification">
                                    // <img src="" alt="" />
                                    <div className="ico-alt ico-plann"></div>
                                    <span className="link-separator"></span>
                                    <h3>Plannification</h3>
                                </Link>
                            </li> */}
                        </ul>
                        }
                    </div>
                    <div className="nav-tab-2">
                        <ul>
                            <li className="tab tab10">
                                <Link to="/settings">
                                    {/* <img src="" alt="" /> */}
                                    <div className="ico-alt ico-setting"></div>
                                    <span className="link-separator"></span>
                                    <h3>Setting</h3>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}


export default PcNav;