import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';
import { genSsoToken, getIsMobile } from './scripts/clientjs';
// import { Helmet } from 'react-helmet';
import './styles/App.css';
import MoHeader from './component/MoHeader';
import PcHeader from './component/PcHeader';
import MoNav from './component/MoNav';
import PcNav from './component/PcNav';
import Login from './pages/Login';
import Activation from './pages/Activation';
import UserDashboard from './pages/UserDashboard';
import AdminDashboard from './pages/AdminDashboard';
// import Plannification from './pages/Plannification';
import Settings from './pages/Settings';
import AdminClients from './pages/AdminClients';



function App() {
  const isMobile = getIsMobile();
  const navigate = useNavigate();

  const ssoToken = genSsoToken();
  const pathname = window.location.pathname;
  const isPublicRoad = pathname === '/activation' || pathname === '/';

  const [userData, setUserData] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');


  
  console.log("isPublicRoad", isPublicRoad);
  
  useEffect(() => {
    const fetchData = async () => {
      if (pathname !== '/activation') {
        try {
          if (!userData && error === '' && isLoggedIn === false) {
            const response = await axios.post('http://localhost:8000/ssoCheck', {
              ssoToken: ssoToken,
            });
            if (response.status === 200) {
              console.log("200");
              setUserData(response.data.userData);
              setIsLoggedIn(true);
            } else {
              console.log("not 200 logs");
              navigate('/');
            //   setError(response.data.error);
            //   const logResponse = await axios.post('http://localhost:8000/logs', {
            //     source: 'REACT-APP',  
            //     action: 'SSO Check',
            //     desc: response.data.error,
            //   });
            //   if (logResponse.status === 200) {
            //     console.log("Log saved");
            //     navigate('/');
            //   } else {
            //     console.log("Log not saved");
            //     navigate('/');
            // }
            }
          }
        } catch (error) {
          console.log("not 200 error");
          setError(error);
          navigate('/');
          // const logResponse = await axios.post('http://localhost:8000/logs', {
          //   source: 'REACT-APP',
          //   action: 'SSO Check',
          //   desc: response.data.error,
          // });
          // if (logResponse.status === 200) {
          //   console.log("Log saved");
          // }
        }
      }
    };
  
    fetchData();
  }, [pathname, ssoToken, userData, error, isLoggedIn, navigate]);



  return (
  <>
    {isMobile ? (
      <>
        {!isPublicRoad ? (
          <>
            {userData ? (
              <>
                <MoNav userData={userData} />
                <div className="moMain">
                  <div className="main">
                    <MoHeader/>
                    <Routes>
                      {/* any */}
                      {/* <Route path="/activation" element={<Activation />}/>
                      <Route path="/" element={<Login userData={userData} isLoggedIn={isLoggedIn} />} /> */}
                      
                      {/* all role */}
                      <Route path="/settings" element={userData ? <Settings userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/" />} />

                      {/* user(1) role */}
                      <Route path="/upload" element={userData.role === 1 ? <UserDashboard userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/admindashboard" />} />
                      {/* <Route path="/plannification" element={userData ? <Plannification userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/admindashboard" />} /> */}

                      {/* admin(0) role */}
                      <Route path="/admindashboard" element={userData.role === 0 ? <AdminDashboard userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/upload" />} />
                      <Route path="/clients" element={userData.role === 0 ? <AdminClients userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/upload" />} />
                    </Routes>
                  </div>
                </div>
              </>
            ) : (
              <div className='loading'><div class="dot-windmill"></div></div>
            )}
          </>
          ) : (
          <>
            <div className="moMain">
              <div className="main">
                <MoHeader/>
                <Routes>
                  {/* any */}
                  <Route path="/activation" element={userData === undefined ? (<Activation />) : userData.role === 1 ? (<Navigate to="/upload" />) : userData.role === 0 ? (<Navigate to="/admindashboard" />) : null} />
                  <Route path="/" element={userData === undefined ? (<Login userData={userData} isLoggedIn={isLoggedIn} />) : userData.role === 1 ? (<Navigate to="/upload" />) : userData.role === 0 ? (<Navigate to="/admindashboard" />) : null} />
                </Routes>
              </div>
            </div>
          </>
          )}
        </>
    ) : (
      <>
        {!isPublicRoad ? (
          <>
            {userData ? (
              <>
                <PcNav userData={userData} />
                <div className="pcMain">
                  <div className="main">
                    <PcHeader/>
                    <Routes>
                      {/* any */}
                      {/* <Route path="/activation" element={<Activation />}/>
                      <Route path="/" element={<Login userData={userData} isLoggedIn={isLoggedIn} />} /> */}
                      
                      {/* all role */}
                      <Route path="/settings" element={userData ? <Settings userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/" />} />

                      {/* user(1) role */}
                      <Route path="/upload" element={userData.role === 1 ? <UserDashboard userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/admindashboard" />} />
                      {/* <Route path="/plannification" element={userData ? <Plannification userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/admindashboard" />} /> */}

                      {/* admin(0) role */}
                      <Route path="/admindashboard" element={userData.role === 0 ? <AdminDashboard userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/upload" />} />
                      <Route path="/clients" element={userData.role === 0 ? <AdminClients userData={userData} isLoggedIn={isLoggedIn} /> : <Navigate to="/upload" />} />
                    </Routes>
                  </div>
                </div>
              </>
            ) : (
              <div className='loading'><div class="dot-windmill"></div></div>
            )}
          </>
          ) : (
          <>
            <div className="moMain">
              <div className="main">
                <PcHeader/>
                <Routes>
                  {/* any */}
                  <Route path="/activation" element={userData === undefined ? (<Activation />) : userData.role === 1 ? (<Navigate to="/upload" />) : userData.role === 0 ? (<Navigate to="/admindashboard" />) : null} />
                  <Route path="/" element={userData === undefined ? (<Login userData={userData} isLoggedIn={isLoggedIn} />) : userData.role === 1 ? (<Navigate to="/upload" />) : userData.role === 0 ? (<Navigate to="/admindashboard" />) : null} />
                </Routes>
              </div>
            </div>
          </>
          )}
        </>
    )}
    </>
  );
}

export default App;
