import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Login.css';
import { genSsoToken, getDeviceInfo } from '../scripts/clientjs';

function Login() {
  const [username, setUsername] = useState('');

  const sendAccessRequest = async (e) => {
    e.preventDefault();
    const ssoToken = genSsoToken();
    const deviceInfo = getDeviceInfo();
    try {
      const response = await axios.post('http://localhost:8000/ssoAccessRequest', {
        ssoToken: ssoToken,
        deviceInfo: deviceInfo,
        username: username,
      });
  
      if (response.status === 200) {
        alert("Access request sent");
      } else {
        alert("Error: " + response.data.error);
        // await axios.post('http://localhost:8000/logs', {
        //   source: 'REACT-APP',
        //   action: 'SSO Access Request (add)',
        //   desc: response.data.error,
        // });
      }
    } catch (error) {
      alert("Error: " + error);
      // await axios.post('http://localhost:8000/logs', {
      //   source: 'REACT-APP',
      //   action: 'SSO Access Request (send)',
      //   desc: error,
      // });
    }
  };
  

  return (
    <div className="login-container">
      <div className="logo-alt"></div>
      <div className="login-form-container">
        <form onSubmit={ sendAccessRequest }>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" onChange={event => setUsername(event.target.value)} />
          </div>
          <button type="submit">Send access request</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
