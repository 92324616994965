import '../styles/AdminClients.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';



function AdminClients(props) {
  const { isLoggedIn, userData } = props;
  const [affichage, setAffichage] = useState(false);
  const [allClients, setAllClients] = useState([]);

  const clients = async () => {
    try {
      const response = await axios.get(`http://localhost:8000/clients`);
      if (response.status === 200) {
        console.log(response.data.clients);
        setAllClients(response.data.clients);
        setAffichage(true);
      }
    } catch (error) {
      console.error("Error fetching user devices:", error);
    }
};

useEffect(() => {
    clients();
}, [userData]);

    const stopContract = async (idSSO) => {
        const confirmed = window.confirm("Are you sure you want to remove this contract ?");
        try {
            if (confirmed) {
                await axios.post(`http://localhost:8000/deletContract/${idSSO}`);
                clients();
            }
        } catch (error) {
            console.error("Error deleting device:", error);
        }
    };

    const genActivationLink = async (idSSO) => {
        try{
            await axios.post(`http://localhost:8000/genActivationLink/${idSSO}`);
        }
        catch (error) {
            console.error("Error generating activation link:", error);
        }
    };


    const addContract = async (idSSO) => {
        try{
            await axios.post(`http://localhost:8000/addContract/${idSSO}`);
            clients();
        }
        catch (error) {
            console.error("Error adding contract:", error);
        }
    };




  return (
        <div className='clients'>
            <h2>Clients</h2>
            <div className='clients-list card1-list'>
                {affichage ? (
                    allClients.map((client, index) => (
                    <div className='clients-item card1' key={index}>
                        <div className="clients-item-info card1-info">
                            <div className="ico-alt icone-client"></div>
                            <div className="clients-info-txt card1-info-txt">
                                <h3 className='clients-name'>{client.firstName}</h3>
                                <p className='clients-email'>{client.email}</p>
                            </div>
                        </div>
                        <div className="clients-item-action card1-action">
                            {client.active ? (
                            <>
                                <button className="btn btn-primary" onClick={() => stopContract(client.idSSO)}>Delete contract</button>
                                <button className="btn btn-primary" onClick={() => {window.open(client.fileLink, '_blank')}}>View contract</button>
                                <button className="btn btn-primary" onClick={() => genActivationLink(client.idSSO)}>Generate ALink</button>
                            </>
                            ) : (
                                <button className="btn btn-primary" disabled onClick={() => addContract(client.idSSO)}>Add contract</button>
                            )}
                            
                        </div>
                    </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
                <div className='add-card'>
                    <div className="add-card-ctnt">
                        <svg className='add-card-icon' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Groupe_4" data-name="Groupe 4" transform="translate(-1160 -512)"><line id="Ligne_5" data-name="Ligne 5" y2="45" transform="translate(1185.5 514.5)" fill="none" stroke="#ccc" stroke-linecap="round" stroke-width="5"/><line id="Ligne_6" data-name="Ligne 6" x2="45" transform="translate(1162.5 537.5)" fill="none" stroke="#ccc" stroke-linecap="round" stroke-width="5"/></g></svg>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default AdminClients;
