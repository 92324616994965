import '../styles/Settings.css';
import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function Plannification(props) {
    const { isLoggedIn, userData } = props;
    const [username , setUsername] = useState(userData.username);
    const [email , setEmail] = useState(userData.email);
    const [allUserDevices, setAllUserDevices] = useState([]);
    const [affichage, setAffichage] = useState(false);

    const userDevices = async () => {
        try {
          const response = await axios.get(`http://localhost:8000/userDevices/${userData.userId}`);
          if (response.status === 200) {
            console.log(response.data.devices);
            setAllUserDevices(response.data.devices);
            setAffichage(true);
          }
        } catch (error) {
          console.error("Error fetching user devices:", error);
        }
    };
      
    useEffect(() => {
        userDevices();
    }, [userData]);

    
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            await axios.put('http://localhost:8000/clientUpdateInfo', { username, email, userId: userData.userId })
            console.log("ok");
        } catch (error) {
            console.error("Error updating user info:", error);
        }
    };


    const deviceDelet = async (idSSO) => {
        const confirmed = window.confirm("Are you sure you want to remove/refuse this device?");
        try {
            if (confirmed) {
            await axios.delete(`http://localhost:8000/deviceDelet/${idSSO}`);
            userDevices();
            }
        } catch (error) {
            console.error("Error deleting device:", error);
        }
    };

    const deviceAuthorize = async (idSSO) => {
        try {
            await axios.put(`http://localhost:8000/ssoAccessAuthorize/${idSSO}`);
            userDevices();
        } catch (error) {
            console.error("Error authorize device:", error);
        }
    };

    return (
        <div className='settings'>
            <div className='my-info'>
                <h2>My information</h2>
                <form onSubmit={handleSubmit}>
                    <p>
                        <label htmlFor='username'>Username:</label>
                        <input type='text' id='username' name='username' value={username} placeholder='ShanXo' onChange={event => setUsername(event.target.value)} />
                    </p>
                    <p>
                        <label htmlFor='email'>Email:</label>
                        <input type='email' id='email' name='email' value={email} placeholder='itsmeshanxxo@gmail.com' onChange={event => setEmail(event.target.value)} />
                    </p>
                    <button type='submit'>Apply</button>
                </form>
            </div>
            <div className='my-device'>
                <h2>My devices</h2>
                <div className='my-device-list'>
                    {affichage ? (
                        allUserDevices.map((device, index) => (
                        <div className='my-device-item' key={index}>
                            <div className="my-device-item-info">
                                {device.authorize === 1 ? (
                                    <><div className="ico-alt ico-device"></div></>
                                ) : (
                                    <><div className="not-active ico-alt ico-device"></div></>
                                )}
                                <div className="my-device-item-info-txt">
                                    <h3 className='my-device-fullname'>{device.deviceType === "mobile" ? device.deviceVendor + " " + device.deviceName : device.deviceOS + " " + device.deviceOSVersion}</h3>
                                    <h3 className='my-device-browser'>{device.deviceBrowser}</h3>
                                </div>
                            </div>
                            <div className="my-device-item-action">
                                {device.authorize === 1 ? (
                                    <button className="btn btn-primary" onClick={() => deviceDelet(device.idSSO)}>Remove</button>
                                ) : (
                                    <>
                                    <button className="btn btn-primary" onClick={() => deviceDelet(device.idSSO)}>Refuse</button>
                                    <button className="btn btn-primary" onClick={() => deviceAuthorize(device.idSSO)}>Approuve</button>
                                    </>
                                )}
                            </div>
                        </div>
                        ))
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Plannification;