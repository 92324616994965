function stateOfMoNav(state) {
    const moNavElement = document.querySelector('.mo-nav');
    const closToggleButton = document.querySelector('.close-toggle-button');
    switch (state) {
      case "open":
      case "o":
        if (moNavElement.classList.contains('mo-nav-close')) {
          moNavElement.classList.replace('mo-nav-close', 'mo-nav-open');
          setTimeout(() => {
            closToggleButton.classList.replace('disabled', 'active');
          }, 150);
        } else {console.log("mo-nav already open");}
        break;
      case "close":
      case "c":
        if (moNavElement.classList.contains('mo-nav-open')) {
          moNavElement.classList.replace('mo-nav-open', 'mo-nav-close');
          closToggleButton.classList.replace('active', 'disabled');
        } else {console.log("mo-nav already close");}
        break;
      case "toggle":
      case "t":
        if (moNavElement.classList.contains('mo-nav-close')) {
          moNavElement.classList.replace('mo-nav-close', 'mo-nav-open');
          setTimeout(() => {
            closToggleButton.classList.replace('disabled', 'active');
          }, 150);
        } else {
          moNavElement.classList.replace('mo-nav-open', 'mo-nav-close');
          closToggleButton.classList.replace('active', 'disabled');
        }
        break;
      default:
        console.log("stateOfMoNav() error: state must be 'open||o' or 'close||c' or 'toggle||t'");
        break;
    }
}

// renvoie si le menue est actuellement ouvert ou fermer
function stateOfMoNavIsOpen() {
  const moNavElement = document.querySelector('.mo-nav');
  if (moNavElement.classList.contains('mo-nav-open')) {
    return true;
  } else if (moNavElement.classList.contains('mo-nav-close')) {
    return false;
  } else {
    console.log("stateOfMoNavIsOpen() error: mo-nav is not open or close");
    return false;
  }
}

export { stateOfMoNav, stateOfMoNavIsOpen };