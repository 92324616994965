import React from 'react';
import { stateOfMoNav } from '../scripts/stateOfMoNav';
import '../styles/MoHeader.css';

function MoHeader() {
  const pathname = window.location.pathname;

  const pageName = () => {
    if (pathname === "/upload") {
      return "Upload content";
    } else if (pathname === "/admindashboard") {
      return "Admin Dashboard";
    } else if (pathname === "/plannification") {
      return "Plannification";
    } else if (pathname === "/settings") {
      return "Settings";
    } else if (pathname === "/clients") {
      return "Clients";
    } else {
      return
    }
  };

  const handleClick = () => {
      stateOfMoNav("toggle");
  };

  
    
  return ( pathname === "/" || pathname === "" || pathname === "/activation" ? null :
    <>
    <header>
      <div className='left'>
        <div className="toggle-button">
          <svg xmlns="http://www.w3.org/2000/svg" className="toggle-button-svg" onClick={handleClick} width="30" height="35" viewBox="0 0 30.409 36.5">
            <g id="menu" transform="translate(-684 -380)">
              <path id="Vector" d="M0,0H21.284" transform="translate(688.563 390.646)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-width="2"/>
              <path id="Vector-2" data-name="Vector" d="M0,0H21.284" transform="translate(688.563 398.25)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-width="2"/>
              <path id="Vector-3" data-name="Vector" d="M0,0H21.284" transform="translate(688.563 405.854)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-width="2"/>
              <path id="Vector-4" data-name="Vector" d="M0,0H30.409V36.5H0Z" transform="translate(684 380)" fill="none" opacity="0"/>
            </g>
          </svg>
        </div>
      </div>
      <div className='page-name'>
        <h1>{pageName()}</h1>
      </div>
      <div className='right'>
        <div className="close-toggle-button disabled">
          <svg xmlns="http://www.w3.org/2000/svg" className="toggle-button-svg" onClick={handleClick} width="19.353" height="19.353" viewBox="0 0 19.353 19.353">
            <g id="Groupe_22" data-name="Groupe 22" transform="translate(-49.406 -35.495)">
              <path id="Vector" d="M16.525,16.525,0,0" transform="translate(50.821 36.909)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-width="2"/>
              <path id="Vector-2" data-name="Vector" d="M0,16.525,16.525,0" transform="translate(50.821 36.909)" fill="none" stroke="#292d32" stroke-linecap="round" stroke-width="2"/>
            </g>
          </svg>
        </div>
      </div>
    </header>
    </>
  );
}

export default MoHeader;