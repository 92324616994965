import '../styles/Activation.css';
import { useNavigate } from 'react-router-dom';
import { genSsoToken, getDeviceInfo } from '../scripts/clientjs';
import React, { useState } from 'react';
import axios from 'axios';


function Activation() {
    const ssoToken = genSsoToken();
    const deviceInfo = getDeviceInfo();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [activated, setActivated] = useState(false);
    const linkName = window.location.search.split('=')[1];

    if (linkName === undefined) {
        navigate('/');
    }

    const handleActivation = (e) => {
        e.preventDefault();
        console.log("ssoToken", ssoToken);
        axios.post('http://localhost:8000/ssoAuthLink', {
          ssoToken: ssoToken,
          deviceInfo: deviceInfo,
          linkName: linkName,
        })
          .then((response) => {
            if (response.data.success === true) {
              setActivated(true);
                setTimeout(() => {
                    navigate('/upload');
                }, 5000);
            } else {
              setError(response.data.error);
              // await axios.post('http://localhost:8000/logs', {
              //   source: 'REACT-APP',
              //   action: 'SSO Auth Link',
              //   desc: response.data.error,
              // });
            }
          })
          .catch((error) => {
            setError(error);
          });
      };
      
      
      
      


    return ( activated === false ? (
        <>
            <h1>Activation</h1>
            <p>Activer votre compte en cliquant simplemant sur le bouton ci dessous</p>
            <button onClick={handleActivation}>Activate my account</button>
            <p>{error && error.message}</p>
        </>
    ) : (
        <>
            <h1>Bienvenue !</h1>
            <p>Vous allez etre fediriger vers votre espace personel</p>
            <p>{error && error.message}</p>
        </>
    )

    );
  }
  
  export default Activation;