import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Compressor from 'compressorjs';
import { Navigate } from 'react-router-dom';


import '../styles/UserDashboard.css';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function UserDashboard(props) {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const { isLoggedIn, userData } = props;
  // const navigate = useNavigate();

  const handleDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const validFiles = acceptedFiles.filter((file) => file.type.startsWith('image/') || file.type.startsWith('video/'));

    setFiles((prevFiles) =>
      prevFiles.concat(
        validFiles.map((file) =>
          Object.assign(file, {
            id: Math.random().toString(36).substr(2, 9),
            preview: URL.createObjectURL(file),
            fileType: file.type.startsWith('image/') ? 'image' : 'video'
          })
        )
      )
    );
  }, []);

  const removeFile = useCallback((file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));
  }, []);

  const removeAllFiles = () => {
    setFiles([]);
  };

  useEffect(() => {
    return () => {
      // Révoque les URL d'objet lorsque le composant est démonté
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*, video/*',
    maxSize: 21474836480, // 20 Go
    onDrop: handleDrop
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const handleUploadClick = async () => {
    if (files.length === 0) {
      return;
    }
  
    const uploadPromises = files.map(async (file) => {
      const formData = new FormData();
  
      // Vérifier si le fichier est une image avant de compresser
      if (file.type.startsWith('image/')) {
        await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.9,
            success(result) {
              formData.append('file', result, result.name);
              resolve();
            },
            error(err) {
              reject(err);
            }
          });
        });
      } else {
        formData.append('file', file);
      }

      formData.append('fileType', file.fileType); // Ajout de la propriété "fileType"

      formData.append('userId', userData.userId);

      return axios
        .post('http://localhost:8000/upload', formData, {
          onUploadProgress: (progressEvent) => {
            const percentage = ((progressEvent.loaded * 100) / progressEvent.total).toFixed(2);
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.id]: percentage
            }));
          }
        })
        .then((res) => {
          console.log(`Upload successful for file ${file.id}`);
          removeFile(file);
          return res.data;
        })
        .catch((err) => {
          console.error(`Error uploading file ${file.id}:`, err);
          // Gérez les erreurs d'envoi
          throw err;
        });
    });
  
    try {
      await Promise.all(uploadPromises);
      console.log('All files uploaded successfully.');
    } catch (err) {
      console.error('Error uploading files:', err);
      // Gérez les erreurs d'envoi globales ici
    }
  };
  

  const filePreviews = files.map((file) => {
    const fileExtension = file.name.split('.').pop();
    // const fileExtension = file.name ? file.name.split('.').pop() : '';
    // const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
    return (
      <div key={file.id} className="file-preview">
        {file.type.startsWith('image/') ? (
          <img src={file.preview} alt={file.name} className="file-preview-image" style={{ width: 100, height: 115 }} />
        ) : (
          <video src={file.preview} alt={file.name} className="file-preview-video" style={{ width: 100, height: 115 }} />
        )}

        <div className="file-preview-details">
          {/* <span className="file-preview-name">{file.name}</span> */}
          {/* <span className="file-preview-size">{file.size} bytes</span> */}
          <span className="file-preview-type">{fileExtension}</span>
       </div>

        {uploadProgress[file.id] !== undefined ? (
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${uploadProgress[file.id]}%` }}></div>
          </div>
        ) : (
          <button className="remove-file-button" onClick={() => removeFile(file)}>
            Remove
          </button>
        )}
      </div>
    );
  });

  return (
    <div className="dropzone-ctnt">
      <div className="dropzone-interface">
        <div className="drop-actions">
          <div className="dropzone" {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop files here!</p> : <p>Drag and drop files here, or click to select files</p>}
          </div>
          {files.length > 0 && (
            <div className='actions'>
              <button className="remove-all-button" onClick={removeAllFiles}>Remove All</button>
              <button className="upload-button" onClick={handleUploadClick}>Upload All</button>
            </div>
          )}
        </div>
        {files.length > 0 && <div className="file-previews">{filePreviews}</div>}
      </div>
    </div>
  );
}

export default UserDashboard;
