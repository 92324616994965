import { ClientJS } from 'clientjs';

const client = new ClientJS();

function genSsoToken() {
    const os = client.getOS();
    const cpu = client.getCPU();
    const fonts = client.getFonts();
    const device = client.getDevice();
    const language = client.getLanguage();
    const timezone = client.getTimeZone();
    const mobileIOS = client.isMobileIOS();
    const mimeTypes = client.getMimeTypes();
    const userAgent = client.getUserAgent();
    const oSVersion = client.getOSVersion();
    const cookiesEnabled = client.isCookie();
    const colorDepth = client.getColorDepth();
    const deviceType = client.getDeviceType();
    const canvasPrint = client.getCanvasPrint();
    const deviceVendor = client.getDeviceVendor();
    const mobileAndroid = client.isMobileAndroid();
    const engineVersion = client.getEngineVersion();
    const systemLanguage = client.getSystemLanguage();
    const localStorageEnabled = client.isLocalStorage();
    const sessionStorageEnabled = client.isSessionStorage();

    const ssoToken = client.getCustomFingerprint( userAgent, colorDepth, device, localStorageEnabled, sessionStorageEnabled, timezone, deviceType, fonts, language, deviceVendor, systemLanguage, cookiesEnabled, canvasPrint, cpu, engineVersion, os, mobileAndroid, mobileIOS, mimeTypes, oSVersion )
    
    return ssoToken;
}

function getDeviceInfo() {
    const os = client.getOS();
    const osVersion = client.getOSVersion();
    const device = client.getDevice();
    const browser = client.getBrowser();
    const deviceType = client.getDeviceType();
    const deviceVendor = client.getDeviceVendor();

    const deviceInfo = {
        os: os,
        device: device,
        browser: browser,
        osVersion: osVersion,
        deviceType: deviceType,
        deviceVendor: deviceVendor,
    }

    return deviceInfo;
}

function getIsMobile() {
    const isMobile = client.isMobile();

    return isMobile;
}

export { genSsoToken, getDeviceInfo, getIsMobile};